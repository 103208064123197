<h2 mat-dialog-title>{{data.challenge.name}}</h2>
<mat-dialog-content class="mat-typography">
  <form class="m-t-30" #challengeForm="ngForm">
    <div class="d-flex justify-content-between">
      <!-- add video with preview -->
      <div class="d-flex justify-content-start flex-column m-r-20 col-3">
        <div class="m-b-16">
          <h2 class="m-0 mat-headline-2 f-w-600 m-b-16">Video</h2>
          <video controls loop class="rounded w-100"
            [src]="data.challenge.videoUrl"
            *ngIf="data.challenge.videoUrl"></video>
          <div class="bb-input-file">
            <input type="file" accept="video/*" ngModel name="video"
              class="w-100" (change)="onFileSelect($event)" required />
          </div>
        </div>
      </div>
      <div class="w-100">
        <h2 class="m-0 mat-headline-2 f-w-600 m-b-16">Informations</h2>
        <div class="d-flex justify-content-between">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput ngModel name="name" required
              [(ngModel)]="data.challenge.name" #nameInput />
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 m-l-10">
            <mat-label>Tag</mat-label>
            <span matPrefix class="m-10">#</span>
            <input matInput ngModel name="tag" required
              [(ngModel)]="data.challenge.tag" (keyup)="checkUnicity($event)" />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Description</mat-label>
          <textarea matInput ngModel name="description" required #description
            maxlength="400" rows="3"
            [(ngModel)]="data.challenge.description"></textarea>
          <mat-hint class="f-s-10" align="end">{{ description.value.length }} /
            400</mat-hint>
        </mat-form-field><br /><br />

        <div class="d-flex justify-content-between">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Earnable Trophies</mat-label>
            <input type="number" matInput ngModel name="earnableTrophies"
              required
              [(ngModel)]="data.challenge.earnableTrophies" />
          </mat-form-field>
        </div>

        <div class="d-flex justify-content-between">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Start Date</mat-label>
            <input type="datetime-local" matInput ngModel name="startDate"
              required
              [ngModel]="this.dateService.displayDateForForm(data.challenge.startDate)" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 m-l-10">
            <mat-label>End Date</mat-label>
            <input type="datetime-local" matInput ngModel name="endDate"
              required
              [ngModel]="this.dateService.displayDateForForm(data.challenge.endDate)" />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Type</mat-label>
          <mat-select matInput ngModel name="type"
            [(ngModel)]="data.challenge.type">
            <mat-option value></mat-option>
            <mat-option value="video">Video</mat-option>
            <!--<mat-option value="image">Image</mat-option> -->
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Category</mat-label>
          <mat-select matInput ngModel name="categoryId"
            [(ngModel)]="data.challenge.category.id">
            <mat-option value></mat-option>
            <mat-option *ngFor="let category of categoryDataSource"
              [value]="category.id">{{ category.icon }} {{
              category.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-divider class="m-t-20 m-b-20"></mat-divider>

        <div class="rounded p-24" style="background-color: #ff000005;"
          *ngIf="data.reason !== 'create' && data.reason !== 'transform'">

          <button mat-flat-button color="primary" class="w-100"
            [disabled]="this.loading"
            (click)="deleteChallenge()">
            <mat-icon>delete</mat-icon>
            Supprimer le challenge ({{data.challenge.name}})
          </button>

          <div class="m-t-20 text-error" role="alert">
            <b>Avertissement:</b>
            Cette action est irréversible et supprimeras toutes les données
            associées à ce challenge.
            (vidéos, participations, likes, commentaires, trophées, etc.)
          </div>
        </div>

      </div>
    </div>
  </form>

</mat-dialog-content>

<!-- buttons -->
<mat-dialog-actions class="m-10" align="center">

  <div class="text-error" role="alert" *ngIf="errorMessages">
    {{errorMessages}}
  </div>

  <button mat-flat-button color="primary" *ngIf="data.reason === 'create'"
    [disabled]="this.loading"
    (click)="createChallenge(challengeForm)">
    <mat-icon>check</mat-icon>
    Créer le challenge
  </button>

  <button mat-flat-button color="primary"
    *ngIf="data.reason === 'transform' && data.proposition !== null"
    [disabled]="this.loading"
    (click)="transformPropositionInChallenge(challengeForm)">
    <mat-icon>bolt</mat-icon>
    Transformer la proposition en challenge
  </button>

  <button mat-flat-button color="primary" *ngIf="data.reason === 'update'"
    [disabled]="this.loading"
    (click)="updateChallenge(challengeForm)">
    <mat-icon>save</mat-icon>
    Enregistrer les modifications
  </button>

  <button mat-button mat-dialog-close>Annuler</button>

</mat-dialog-actions>
