import { gql } from '@apollo/client/core';

export const CLOSE_REPORT = gql`
  mutation closeReport($reportId: Int!) {
    closeReport(
      reportId: $reportId
    ) {
      id
    }
  }
`;

export const BAN_USER = gql`
  mutation banUser($userId: String!, $reason: String!, $startDate: DateTime!, $endDate: DateTime) {
    banUser(
      userId: $userId,
      reason: $reason,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
    }
  }
`;

export const UNBAN_USER = gql`
  mutation debanUser($userId: String!) {
    debanUser(
      userId: $userId
    )
  }
`;

export const BLOCK_CHALLENGE = gql`
  mutation blockChallenge($challengeId: Int!, $reason: String!) {
    blockChallenge(
      challengeId: $challengeId,
      reason: $reason
    ) {
      id
    }
  }
`;

export const BLOCK_PARTICIPATION = gql`
  mutation blockParticipation($participationId: Int!, $reason: String!) {
    blockParticipation(
      participationId: $participationId,
      reason: $reason
    ) {
      id
    }
  }
`;
