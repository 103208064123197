import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CHECK_UNICITY, GET_CHALLENGES, GET_CHALLENGE_BY_ID } from '../graphql/challenge/queries';
import { DELETE_CHALLENGE } from '../graphql/challenge/mutations';
import { SUBSCRIBE_CHALLENGE_ENCODING } from '../graphql/challenge/subscriptions';

export const challengeStatus = {
  released: { value: "released", label: "Publié" },
  refused: { value: "refused", label: "Refusé" },
  finished: { value: "finished", label: "Fermé" },
  waitingValidation: { value: "waiting_validation", label: "En attente de validation" },
  waitingRelease: { value: "waiting_release", label: "En attente de publication" },
}

export const creatorTypes = {
  king: { value: 'king', label: "Roi d'arène" },
  master: { value: 'master', label: "Maitre d'arène" },
}

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  constructor(private apollo: Apollo) { }

  getChallenge(
    status: string[] | null = [
      challengeStatus.refused.value,
      challengeStatus.released.value,
      challengeStatus.waitingValidation.value,
      challengeStatus.waitingRelease.value
    ],
    categoryId?: string,
    userId?: string,
    offset: number = 0,
    count: number = 10,
    createdBy?: string
  ) {

    var variables: any = {
      pagination: {
        offset: offset,
        count: count
      }
    }

    if (categoryId) {
      variables.categoryId = categoryId;
    }

    if (createdBy) {
      variables.createdBy = createdBy;
    }

    if (status) {
      variables.status = status;
    }

    if (userId) {
      variables.userId = userId;
    }

    return this.apollo.query({
      query: GET_CHALLENGES,
      variables: variables
    })
  }

  getChallengeById(id: number) {
    return this.apollo.query({
      query: GET_CHALLENGE_BY_ID,
      variables: {
        id: id
      }
    })
  }

  deleteChallenge(id: number) {
    return this.apollo.mutate({
      mutation: DELETE_CHALLENGE,
      variables: {
        id: id
      }
    })
  }

  subscribeChallengeEncoding(challengeId: number) {
    return this.apollo.subscribe({
      query: SUBSCRIBE_CHALLENGE_ENCODING,
      variables: {
        challengeId: challengeId
      }
    })
  }

  checkUnicity(text: string) {
    return this.apollo.query({
      query: CHECK_UNICITY,
      variables: {
        text: text,
        type: "CHALLENGE_TAG"
      }
    })
  }

}
