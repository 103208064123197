import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_BANS, GET_BLAMES, GET_REPORTS, GET_REPORT_REASONS } from '../graphql/report/queries';
import { BAN_USER, BLOCK_CHALLENGE, BLOCK_PARTICIPATION, CLOSE_REPORT, UNBAN_USER } from '../graphql/report/mutations';

export const reportStatus = {
  closed: { value: 'closed', label: 'Fermé' },
  pending: { value: 'pending', label: 'En attente' },
}

export const banDuration = {
  oneDay: { value: '1', label: '1 jour' },
  threeDays: { value: '3', label: '3 jours' },
  oneWeek: { value: '7', label: '1 semaine' },
  oneMonth: { value: '30', label: '1 mois' },
  threeMonths: { value: '90', label: '3 mois' },
  oneYear: { value: '365', label: '1 an' },
  permanent: { value: '0', label: 'Permanent' },
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private apollo: Apollo) { }

  getReports(status: string = '') {
    return this.apollo.query({
      query: GET_REPORTS,
      variables: {
        status: status,
      },
    });
  }

  getReportReasons() {
    return this.apollo.query({
      query: GET_REPORT_REASONS,
    });
  }

  closeReport(reportId: number) {
    return this.apollo.mutate({
      mutation: CLOSE_REPORT,
      variables: {
        reportId: reportId,
      },
    });
  }

  banUser(userId: String, reason: string, startDate: string, endDate: string | undefined) {

    return this.apollo.mutate({
      mutation: BAN_USER,
      variables: {
        userId: userId,
        reason: reason,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  unbanUser(userId: string) {
    return this.apollo.mutate({
      mutation: UNBAN_USER,
      variables: {
        userId: userId,
      },
    });
  }

  blockChallenge(challengeId: number, reason: string) {
    return this.apollo.mutate({
      mutation: BLOCK_CHALLENGE,
      variables: {
        challengeId: challengeId,
        reason: reason,
      },
    });
  }

  blockParticipation(participationId: number, reason: string) {
    return this.apollo.mutate({
      mutation: BLOCK_PARTICIPATION,
      variables: {
        participationId: participationId,
        reason: reason,
      },
    });
  }

  getBlames(userId: string) {
    return this.apollo.query({
      query: GET_BLAMES,
      variables: {
        userId: userId,
      },
    });
  }

  getBans(userId: string) {
    return this.apollo.query({
      query: GET_BANS,
      variables: {
        userId: userId,
      },
    });
  }

}
